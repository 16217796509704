import { Result } from '../core/domain/result';
import { ITransaction } from '../core/domain/transaction/ITransaction';
import { SubscriptionProduct } from '../core/models/subscription/subscriptionProduct';
import { Subscriptions } from '../core/services/subscriptions/subscriptions';

enum PricingTier {
	X,
	Fifteen
}

const version15UrlSafeTagValue = 'version-15';

const productPricingTierPredicateMap: Record<PricingTier, (product: SubscriptionProduct) => boolean> = {
	[PricingTier.Fifteen]: (product) => product.attributes.tags.some(t => t.urlSafe === version15UrlSafeTagValue),
	[PricingTier.X]: (product) => !productPricingTierPredicateMap[PricingTier.Fifteen](product)
};

const getPricingTier = (product: SubscriptionProduct): PricingTier => {
	const pricingTierFunctions: {
		predicate: (product: SubscriptionProduct) => Boolean,
		tier: PricingTier
	}[] = [{
		predicate: productPricingTierPredicateMap[PricingTier.Fifteen],
		tier: PricingTier.Fifteen
	}];

	return pricingTierFunctions.find(p => p.predicate(product))?.tier || PricingTier.X;
};

const filterProductsForTier = (products: SubscriptionProduct[], tier: PricingTier): SubscriptionProduct[] =>
	products.filter(productPricingTierPredicateMap[tier]);

export const reconcilePricingTiers = async (
	productId: string | null,
	transaction: ITransaction,
	locationSearch: string,
	productsResult: Result<SubscriptionProduct[]> | null,
	subscriptions = Subscriptions.Instance()
) => {
	if (!productId || !transaction.PrimarySub) {
		return { locationSearch, productsResult };
	}

	const noSpouseOrMagazineProductPredicate = (p?: SubscriptionProduct) => !p?.attributes.tags.some(t => ['spouse', 'concealed-carry-magazine'].includes(t.urlSafe));
	const allProducts = (await subscriptions.getAllProducts()).value?.filter(noSpouseOrMagazineProductPredicate) || [];
	const currentSubscriptionProduct = allProducts?.find(p => p.id === transaction.PrimarySub?.attributes.productId);
	const currentSubscriptionTier = currentSubscriptionProduct ? getPricingTier(currentSubscriptionProduct) : PricingTier.Fifteen;

	if (noSpouseOrMagazineProductPredicate(currentSubscriptionProduct)) {
		const newProduct = allProducts?.find(p =>
			p.id === productId ||
			p.attributes.externalServicesId === productId ||
			p.attributes.externalId === productId);
		const newProductTier = newProduct ? getPricingTier(newProduct) : PricingTier.Fifteen;

		if (currentSubscriptionTier !== newProductTier) {
			const applicableProducts = filterProductsForTier(allProducts, currentSubscriptionTier);
			const tagsToMatch = ['gold', 'platinum', 'elite', 'monthly', 'annual'].filter(t1 => newProduct?.attributes.tags.find(t2 => t2.urlSafe === t1));
			const correctProductForUserPricing = applicableProducts?.find(
				p => !!tagsToMatch.every(requiredTag => p.attributes.tags.find(t => t.urlSafe === requiredTag)));

			if (correctProductForUserPricing?.id) {
				locationSearch = locationSearch.replace(productId, correctProductForUserPricing.id);
				productsResult = await transaction.SetProductDataById(correctProductForUserPricing.id);
			}
		}
	}

	return { locationSearch, productsResult };
};
