/* eslint-disable max-lines */
import type { BundleProduct, SubscriptionProduct } from '../../core/models/module';
import React, { useEffect, useRef, useState } from 'react';
import { Observable } from 'tsbase/Patterns/Observable/Observable';
import { ITransaction, Transaction } from '../../core/domain/module';
import {
	AmacIntegration,
	Bundle,
	Giveaways,
	GiveawayWithEntriesList,
	IBundle,
	IGiveaways,
	ITracking,
	Tracking
} from '../../core/services/module';
import { ClientSafeTokens, Levels, PaymentFrequencies, QueryParams } from '../../core/enums/module';
import { getGiveawayId } from '../../utilities/getGiveawayId';
import { getQueryParameter } from '../../utilities/getQueryParameter';
import { executeWithinBoundary } from '../../utilities/executeWithinBoundary';
import { cleanStringFromTerms } from '../../utilities/cleanStringFromTerms';
import { getSwiftWindBonus, getSwiftWindConfig, SwiftWindConfig } from '../../utilities/getSwiftWindConfig';
import { Icons } from '../../icons';
import { BundlesByTier, BundlesByTierKeys, BundlesByTierKeysLabelRecord, returnBundlesByTier } from './returnBundlesByTier';
import styles from './bonusesDrawer.module.scss';
import { getLevel } from '../../utilities/getLevel';

type Props = {
	amacIntegrationEnabled?: boolean;
	transaction?: ITransaction;
	bundleService?: IBundle;
	tracking?: ITracking;
	timeout?: number;
	getGiveawayIdFunc?: typeof getGiveawayId;
	giveaways?: IGiveaways;
	getQueryParamFunc?: typeof getQueryParameter;
	returnBundlesByTierFunc?: typeof returnBundlesByTier;
};

let autoCloseTimeout: number | null = null;
export const containerFocused = {
	value: false
};

export const BonusesDisplayed = new Observable<boolean>();
const quarterSecond = 250;

// eslint-disable-next-line complexity
export const BonusesDrawer: React.FunctionComponent<Props> = ({
	amacIntegrationEnabled = AmacIntegration.isEnabled(),
	transaction = Transaction.Instance(),
	bundleService = Bundle.Instance(),
	tracking = Tracking.Instance(),
	timeout = quarterSecond,
	getGiveawayIdFunc = getGiveawayId,
	giveaways = Giveaways.Instance(),
	getQueryParamFunc = getQueryParameter,
	returnBundlesByTierFunc = returnBundlesByTier
}) => {
	const containerRef = useRef<HTMLDivElement>(null);
	const toggleButtonRef = useRef<HTMLButtonElement>(null);
	const [bundleProducts, setBundleProducts] = useState<BundleProduct[]>([]);
	const [sortedBundleProducts, setSortedBundleProducts] = useState<BundlesByTier | undefined>();
	const [purchaseProducts, setPurchaseProducts] = useState<
		| {
			primary?: SubscriptionProduct;
			spouse?: SubscriptionProduct;
		}
		| undefined
	>();
	const [giveaway, setGiveaway] = useState<GiveawayWithEntriesList>();
	const [swiftWindConfig, setSwiftWindConfig] = useState<SwiftWindConfig | undefined>();
	const [isSurprise, setIsSurprise] = useState(false);
	const [userDeniedBonuses, setUserDeniedBonuses] = useState(false);

	const level = getLevel(transaction.Data.purchaseProducts?.primary?.attributes?.sku?.toLowerCase()) || Levels.Elite;
	const swiftWindBonusValue = swiftWindConfig && getSwiftWindBonus(swiftWindConfig, level);
	let changesSubId = '';
	let bonusesDisplayedSubId = '';
	const bonusCount = amacIntegrationEnabled
		? 1
		: (!!giveaway ? giveaway.entries.length : 0) +
		(swiftWindBonusValue ? swiftWindBonusValue.numberOfItems : 0) +
		(bundleProducts.length || (
			(sortedBundleProducts?.eliteAndPlatinum.length || 0) + (sortedBundleProducts?.allTiers.length || 0)
		));
	const cartItemCount = bonusCount + (purchaseProducts?.primary ? 1 : 0) + (purchaseProducts?.spouse ? 1 : 0);
	const fourPointSevenFiveSeconds = timeout * 19;
	const membershipUnselected = !(purchaseProducts?.primary || purchaseProducts?.spouse);
	const hasBundleProducts =
		!!bundleProducts.length || (!!sortedBundleProducts && Object.values(sortedBundleProducts).some((tier) => !!tier.length));
	const shouldShow = amacIntegrationEnabled || !!swiftWindBonusValue || (hasBundleProducts && !userDeniedBonuses);

	const updateDataFromTransaction = async () => {
		setUserDeniedBonuses(transaction.Data.bundleProductsDenied || false);
		setBundleProducts(transaction.Data.bundleProducts || []);
		setPurchaseProducts(transaction.Data.purchaseProducts);
		setGiveaway(transaction.Data.currentGiveaway);
		executeWithinBoundary(async () => {
			const productIdExists = !!getQueryParamFunc(QueryParams.ProductId);
			if (!productIdExists) {
				const allBundleProducts: Record<Levels, BundleProduct[]> | undefined =
					(await bundleService.getBundleProductsForAllLevels()).value || undefined;
				if (allBundleProducts) {
					const sortedBundles = returnBundlesByTierFunc(allBundleProducts);
					setSortedBundleProducts(sortedBundles);
				}
			}
		});
	};

	useEffect(() => {
		BonusesDisplayed.Publish(shouldShow);
	}, [bundleProducts, sortedBundleProducts]);

	useEffect(() => {
		changesSubId = transaction.Updates.Subscribe(updateDataFromTransaction);

		bonusesDisplayedSubId = BonusesDisplayed.Subscribe((b) => {
			if (b) {
				BonusesDisplayed.Cancel(bonusesDisplayedSubId);
				containerRef.current?.focus();

				autoCloseTimeout = setTimeout(() => {
					containerRef.current?.blur();
				}, fourPointSevenFiveSeconds) as any;
			}
		});

		executeWithinBoundary(async () => {
			const campaignId = await getGiveawayIdFunc();
			transaction.Data.currentGiveaway = campaignId ? (await giveaways.getGiveawayCampaign(campaignId)).value : undefined;

			const productId = getQueryParamFunc(QueryParams.ProductId);
			if (productId) {
				const productsResult = await transaction.SetProductDataById(productId);
				if (productsResult.wasSuccessful) {
					const isLegalProduct = !productsResult.value?.find(
						(p) => p.attributes.price === 0 || ['silver', 'lifetime'].some((t) => p.attributes.tier.toLowerCase().includes(t))
					);
					if (isLegalProduct) {
						const dataForProductId = transaction.SubscriptionProductForParam;
						const currentSubIsAnnual =
							transaction.Data.customerSubscriptions?.find((s) => s.attributes.status === 'active' && s.attributes.paymentFrequency)
								?.attributes.paymentFrequency === PaymentFrequencies.Annual;
						const productIdIsAnnual = dataForProductId?.attributes.paymentFrequency === PaymentFrequencies.Annual;
						const frequency: PaymentFrequencies =
							currentSubIsAnnual || productIdIsAnnual ? PaymentFrequencies.Annual : PaymentFrequencies.Monthly;
						transaction.SetPurchaseProducts(frequency as PaymentFrequencies);
						await bundleService.setBundleProductsInTransaction();
					}
				}
			}

			await updateDataFromTransaction();
			setIsSurprise(await bundleService.funnelShouldUseSurpriseBundle());
			const funnelIdResult = await tracking.getFunnelId();
			if (funnelIdResult.wasSuccessful) {
				setSwiftWindConfig(getSwiftWindConfig(funnelIdResult.value || ''));
			}
		});

		return () => {
			transaction.Updates.Cancel(changesSubId);
			BonusesDisplayed.Cancel(bonusesDisplayedSubId);
		};
	}, []);

	const ToggleButtonText = () => {
		const hasExactlyOneBonus = bonusCount === 1;
		const hasLevelSpecificBonuses =
			sortedBundleProducts &&
			Object.values(BundlesByTierKeys).some((tier) => tier != BundlesByTierKeys.AllTiers && sortedBundleProducts[tier]?.length);
		if (isSurprise && !amacIntegrationEnabled) {
			return (
				<span className={styles.smallerText}>
					<span className={styles.hideBelowThreeSixty}>Surprise! </span>
					{bonusCount} bonus {bonusCount > 1 ? 'items' : 'item'} in cart
				</span>
			);
		} else {
			return membershipUnselected ? (
				<span>
					Get{hasLevelSpecificBonuses ? ' up to' : ''} {bonusCount} free {hasExactlyOneBonus ? 'bonus' : 'bonuses'}
				</span>
			) : (
				<span>
					{bonusCount} free {hasExactlyOneBonus ? 'bonus' : 'bonuses'} in cart
				</span>
			);
		}
	};

	return shouldShow ? (
		<div
			tabIndex={0}
			ref={containerRef}
			onFocus={() => setTimeout(() => (containerFocused.value = true), timeout)}
			onBlur={() => setTimeout(() => (containerFocused.value = false), timeout)}
			className={`${styles.container} ${isSurprise ? '' : styles.narrow}`}
		>
			<button
				ref={toggleButtonRef}
				id="bonusesDrawer_toggle_button"
				onClick={() => {
					autoCloseTimeout && clearTimeout(autoCloseTimeout);
					if (containerFocused.value) {
						containerRef.current?.blur();
						toggleButtonRef.current?.blur();
						containerFocused.value = false;
					}
				}}
			>
				<div className={`${styles.cartTextChevronWrapper} ${isSurprise && styles.surprise}`}>
					<ToggleButtonText />
					<div className={styles.togglableIcon}>
						<div className={styles.down}>{Icons.ChevronDown}</div>
						<div className={styles.up}>{Icons.ChevronUp}</div>
					</div>
				</div>

				<div className={styles.cartIconWrapper}>
					{Icons.ShoppingCart}{' '}
					{<span className={`${membershipUnselected && styles.hidden}`}>{!membershipUnselected && cartItemCount}</span>}
				</div>
			</button>

			<ul>
				{amacIntegrationEnabled ? (
					<li key={'amac-account-extension-bonus'}>
						<img src={ClientSafeTokens.AmacLogo} alt={'AMAC logo'} />
						<p>
							<strong>Association of Mature American&nbsp;Citizens</strong>
							<br></br>
							<strong>Free</strong> Account Extension
						</p>
					</li>
				) : (
					<>
						{!!bundleProducts.length ? (
							<>
								{bundleProducts.map((product, i) => (
									<li key={i}>
										<img src={product?.attributes?.image || ''} alt={product?.attributes?.description || ''} />
										<p>
											<strong>Free</strong> {product?.attributes?.description}
											<br />
											{!!product?.attributes?.listPrice && <em>Valued at ${product.attributes.listPrice}</em>}
										</p>
									</li>
								))}
								{!!swiftWindBonusValue && (
									<li>
										<img src={swiftWindBonusValue.imgSrc} alt={swiftWindBonusValue.description} />
										<p>
											<strong>{swiftWindBonusValue.numberOfItems > 1 ? `${swiftWindBonusValue.numberOfItems} Free` : 'Free'}</strong>
											{` ${swiftWindBonusValue.description}`}
											<br />
											{!!swiftWindBonusValue.monetaryValue && <em>Valued at ${swiftWindBonusValue.monetaryValue}</em>}
										</p>
									</li>
								)}
							</>
						) : (
							<>
								{Object.values(BundlesByTierKeys).map((tier) => (
									<>
										{!!sortedBundleProducts?.[tier].length &&
											sortedBundleProducts[tier].map((product, i) => (
												<li key={i}>
													<img src={product?.attributes?.image || ''} alt={product?.attributes?.description || ''} />
													<p>
														<strong>{BundlesByTierKeysLabelRecord[tier]}</strong>
														<br />
														<strong>Free</strong> {product?.attributes?.description} <em>(${product?.attributes?.listPrice})</em>
													</p>
												</li>
											))}
										{tier === BundlesByTierKeys.AllTiers && !!swiftWindBonusValue && (
											<li>
												<img src={swiftWindBonusValue.imgSrc} alt={swiftWindBonusValue.description} />
												<p>
													<strong>{BundlesByTierKeysLabelRecord[tier]}</strong>
													<br />
													<strong>{swiftWindBonusValue.numberOfItems > 1 ? `${swiftWindBonusValue.numberOfItems} Free` : 'Free'}</strong>
													{` ${swiftWindBonusValue.description} `}
													{swiftWindBonusValue.monetaryValue > 1 && <em>(${swiftWindBonusValue.monetaryValue})</em>}
												</p>
											</li>
										)}
									</>
								))}
							</>
						)}
						{giveaway?.entries?.map((g, i) => (
							<li key={i}>
								<img src={g.image} alt="Entry to win" />
								<p>
									<strong>Free Entry to Win</strong>
									<br />
									{g.description}
								</p>
							</li>
						))}
					</>
				)}
				{purchaseProducts?.primary && (
					<li>
						<img src={purchaseProducts.primary.attributes.image} alt="" />
						<p>
							<b>{cleanStringFromTerms(purchaseProducts.primary.attributes.description, ['Monthly', 'Annual', 'USCCA', '15.0'])}</b>
							<br />
							Billed {purchaseProducts.primary.attributes.paymentFrequency === PaymentFrequencies.Monthly ? 'Monthly' : 'Annually'}
						</p>
					</li>
				)}
				{purchaseProducts?.spouse && (
					<li>
						<img src={purchaseProducts.spouse.attributes.image} alt="" />
						<p>
							<b>{cleanStringFromTerms(purchaseProducts.spouse.attributes.description, ['Monthly', 'Annual', 'USCCA', '15.0'])}</b>
							<br />
							Billed {purchaseProducts.spouse.attributes.paymentFrequency === PaymentFrequencies.Monthly ? 'Monthly' : 'Annually'}
						</p>
					</li>
				)}
				{membershipUnselected && (
					<li>
						{Icons.MembershipShield}
						<p>
							<b>
								USCCA Membership<br></br>Plus Free Bonus(es)
							</b>
						</p>
					</li>
				)}
			</ul>
		</div>
	) : (
		<></>
	);
};
