import { URL } from 'url';
import { ITransaction } from '../core/domain/transaction/ITransaction';
import {
	BreakingErrors,
	levelCanUpgradeTo,
	PaymentFrequencies,
	ProductTiers,
	QueryParams,
	Routes
} from '../core/enums/module';
import { getNextRoute } from './getNextRoute';
import { getQueryParameter } from './getQueryParameter';
import { reconcilePricingTiers } from './reconcilePricingTiers';
import { safelyExecute } from './safelyExecute';
import { Result } from '../core/domain/result';
import { SubscriptionProduct } from '../core/models/subscription/subscriptionProduct';
import { isSpouseProduct } from './isSpouseProduct';

export const routeUpgradeAndProductIdPurchases = async (
	transaction: ITransaction,
	router: { push: (route: string) => void },
	reconcilePricingTiersFunction = reconcilePricingTiers,
	mainLocation = window.location
) => {
	let locationSearch = mainLocation.search;
	const productId = getQueryParameter(QueryParams.ProductId, locationSearch);
	const upgradingFromLevel = transaction.UpgradingFromLevel;

	if (productId || upgradingFromLevel || transaction.SpouseOnlyPurchase) {
		let productsResult: Result<SubscriptionProduct[]> | null = null;
		({ locationSearch, productsResult } = await reconcilePricingTiersFunction(
			productId,
			transaction,
			locationSearch,
			productsResult
		));
		productsResult = productsResult || (await transaction.SetProductDataById(productId));

		if (
			!productsResult.wasSuccessful ||
			productsResult.value?.some(
				(p) =>
					p.attributes.price === 0 || ['silver', 'lifetime'].some((t) => p.attributes.tier.toLowerCase().includes(t))
			)
		) {
			transaction.Error.Publish(BreakingErrors.FailedToLoadProducts);
		} else if (!transaction.PrimarySub && !transaction.SpouseSub) {
			if (transaction.IsCCMPurchase()) {
				transaction.SetPurchaseProducts(PaymentFrequencies.Annual);
				router.push(`${getNextRoute(Routes.SignupFrequency)}${locationSearch}`);
			} else {
				await routeAfterSettingPurchaseProducts(transaction, router, mainLocation);
			}
		} else {
			await routeToNextPageForUpgrade(transaction, router, mainLocation);
		}
	} else {
		router.push(`${Routes.Existing}${locationSearch}`);
	}
};

const routeToNextPageForUpgrade = async (
	transaction: ITransaction,
	router: { push: (route: string) => void },
	mainLocation: Location
): Promise<boolean> => {
	const locationSearch = mainLocation.search;
	const productId = getQueryParameter(QueryParams.ProductId, locationSearch);
	const prospectiveProduct = transaction.Data.productData?.find(
		(p) => p.id === productId || !isSpouseProduct(p)
	);
	const prospectiveLevel = prospectiveProduct?.attributes.tier || ProductTiers.Elite;

	const validLevelUpgrade = !!(
		transaction.UpgradingFromLevel && levelCanUpgradeTo(transaction.UpgradingFromLevel, prospectiveLevel)
	);
	const canUpgrade =
		validLevelUpgrade ||
		transaction.IsSameLevelMonthlyToAnnualUpgrade(prospectiveProduct) ||
		transaction.IsSameLevelAuthorizedSideGrade(prospectiveProduct) ||
		transaction.SpouseOnlyPurchase;

	if (canUpgrade) {
		await routeAfterSettingPurchaseProducts(transaction, router, mainLocation);
	} else {
		router.push(`${Routes.Existing}${locationSearch}`);
	}

	return true;
};

const routeAfterSettingPurchaseProducts = async (
	transaction: ITransaction,
	router: { push: (route: string) => void },
	mainLocation: Location
) => {
	const locationSearch = mainLocation.search;
	const dataForProductId = transaction.SubscriptionProductForParam;
	const productFrequency = dataForProductId?.attributes.paymentFrequency;
	if (
		transaction.IsSameLevelAuthorizedSideGrade() &&
		dataForProductId?.attributes.paymentFrequency === PaymentFrequencies.Monthly &&
		transaction.PrimarySub?.attributes.paymentFrequency === PaymentFrequencies.Annual
	) {
		const annualProduct = transaction.Data.productData?.find(
			(p) =>
				p.attributes.paymentFrequency === PaymentFrequencies.Annual &&
				!isSpouseProduct(p)
		);
		const annualId = annualProduct?.id || '';
		if (annualId) {
			await safelyExecute(() => {
				const url = new URL(mainLocation.href);
				url.searchParams.set(QueryParams.ProductId, annualId);
				mainLocation.href = url.href;
			});
		}
	}
	const currentSubFrequency = transaction.Data.customerSubscriptions?.find(
		(s) => s.attributes.status === 'active' && s.attributes.paymentFrequency
	)?.attributes.paymentFrequency;
	const frequency = [productFrequency, currentSubFrequency].includes(PaymentFrequencies.Annual)
		? PaymentFrequencies.Annual
		: PaymentFrequencies.Monthly;

	transaction.SetPurchaseProducts(frequency as PaymentFrequencies);

	const nonSpouseOnlyRoute = getNextRoute(Routes.SignupRegistration, locationSearch, transaction);
	const nextRoute = transaction.SpouseOnlyPurchase ? Routes.SignupSpouse : nonSpouseOnlyRoute;
	router.push(`${nextRoute}${locationSearch}`);
};
