import { Levels } from './levels';
import { ProductTiers } from './productTiers';

export enum UpgradableLevels {
	CCM = 'CCM',
	Silver = 'Silver',
	Gold = 'Gold',
	Platinum = 'Platinum'
}

const UpgradeLevelLevelsRecord: Record<UpgradableLevels, (Levels | ProductTiers)[]> = {
	[UpgradableLevels.CCM]: [
		Levels.Gold,
		Levels.Platinum,
		Levels.Elite,
		ProductTiers.Gold,
		ProductTiers.Platinum,
		ProductTiers.Elite,
		ProductTiers.Gold15,
		ProductTiers.Platinum15,
		ProductTiers.Elite15
	],
	[UpgradableLevels.Silver]: [
		Levels.Gold,
		Levels.Platinum,
		Levels.Elite,
		ProductTiers.Gold,
		ProductTiers.Platinum,
		ProductTiers.Elite,
		ProductTiers.Gold15,
		ProductTiers.Platinum15,
		ProductTiers.Elite15
	],
	[UpgradableLevels.Gold]: [
		Levels.Platinum,
		Levels.Elite,
		ProductTiers.Platinum,
		ProductTiers.Elite,
		ProductTiers.Platinum15,
		ProductTiers.Elite15
	],
	[UpgradableLevels.Platinum]: [
		Levels.Elite,
		ProductTiers.Elite,
		ProductTiers.Elite15
	]
};

export const levelCanUpgradeTo = (
	currentLevel: UpgradableLevels | '',
	prospectiveLevel: Levels | ProductTiers
): boolean => {
	return UpgradeLevelLevelsRecord[currentLevel]?.includes(prospectiveLevel) || false;
};
